import useMutation from '@/hooks/useMutation';
import { useGuest } from '@/context/guest';
import { ACTION_POST_TYPE as POST, API_TABLE_TYPE as TABLE } from '@/constants/api';

/**
 * @function useGiftConfirmation
 * @description custom hook to return gift confirmation handler
 */
function useGiftConfirmation() {
  const { guest } = useGuest();
  const { code } = guest;
  const { onMutation, loading } = useMutation(TABLE.giftConfirmation, POST.insertGiftConfirmation);

  /**
   * @function handlePostConfirmation
   * @param {*} args an object params for post confirmation
   */
  const handlePostConfirmation = async ({
    name,
    country_code = '',
    phone_number = '',
    notes,
    isBankTransfer,
    isGiftCard,
    onSuccess,
    onError,
  }) => {
    const options =
      `${code ? `&guest_code=${code}` : '&guest_code=Announcement'}` +
      `&name=${encodeURIComponent(name)}` +
      `&country_code=${country_code}` +
      `&phone_number=${phone_number}` +
      `&notes=${encodeURIComponent(notes)}` +
      `&bank_transfer=${isBankTransfer}` +
      `&physical_gift=${isGiftCard}`;

    const result = await onMutation(options);

    if (result.success) {
      onSuccess();
    } else {
      onError();
    }
  };

  return { loading, onPostConfirmation: handlePostConfirmation };
}

export default useGiftConfirmation;
