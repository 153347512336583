export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  borderRadius: '0',
  backgroundColor: 'bgAlternative',
  fontFamily: 'serif',
  color: 'mainColorTextLight',
  _placeholder: { color: 'mainColorTextLight' },
  _focus: { borderColor: 'mainColorTextLight' },
};

export const FORM_LABEL_PROPS = {
  fontSize: 'md',
  color: 'mainColorText',
  margin: '8px 0 0 0',
  fontFamily: 'serif',
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgAlternative',
  color: 'mainColorTextLight',
  fontFamily: 'body',
  fontSize: 'md',
  _focus: { borderColor: 'mainColorText' },
};
